import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ProjectImg = ({ filename, alt, className }) => {
  const data = useStaticQuery(graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 95, maxWidth: 700) { 
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(filename);
  });

   const imageFluid = image.node.childImageSharp.fluid;
   return <Img className={className} alt={alt} fluid={imageFluid} />;
    
};


export default ProjectImg;
