import React from "react"
import '../styles/main.scss'
import Layout from '../components/Layout'
import ImagesLoader from '../components/ImagesLoader'
import ThumbnailLoader from '../components/ThumbnailLoader'

export default function About() {
  return (
    <div>
      <Layout>
        <article className="">
          
          <div className='mt4 flex-l justify-between-l items-center-l w-90 w-80-ns center mw9'>  
            <ImagesLoader filename='drwiner-office' className='w5 h5 br-100 ba bw3 b--blue mb4 center w-50-l bn-l br3-l shadow-1 mw6'/>
            <div className="w-50-l w-100 black-70 center pl4-l">
            <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir mh2 bb">Dr. Winer</h3>
              <p className="f4-l f5 lh-copy ma3-l pa2 mr0-l pl0-l ml0-l">
                Graduated from the University of Toronto in 1984 with a Bachelor of Science 
                degree and went on for a Doctor of Optometry degree from the University of Waterloo in 1987. 
                Dr. Winer practices in all areas of vision care with a special interest the area of contact lenses.
              </p>
            </div>
          </div>


          <div className='w-90 w-80-ns center mw9 black-70 mt4'>
            <h3 className="f3 fw7 ttu tracked lh-title mt0 mb4 avenir bb mh2" >Meet Our Staff</h3>
            <div className='flex flex-wrap justify-around w-100 center ma2'>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='charles-square' className='center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Charles<br />Optician</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='dena-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Dena<br />Receptionist</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='renata-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Renata<br />Receptionist</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='kimberly-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Kimberly<br />Receptionist</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='susan-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Susan<br />Optician</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='toula-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Toula<br />Office Manager</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='hugh-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Hugh<br />Optician</div>
              </div>
              <div className='image-container w-25-l w-50-m w-80 hide-child tc pa2'>
                <ThumbnailLoader filename='voula-square' className=' center dim br-100 ba b--blue bw3 shadow-1'/>
                <div className="f5 text child">Voula<br />Receptionist</div>
              </div>
            </div>
           </div>
        </article>
      </Layout>
    </div>
    )
}


//<img src="http://www.eyeconx.net/drwiner/files/Drwiner.png" className="w-100 f5 measure" alt="Dr. Winer"/>
   